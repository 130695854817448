import React from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const userPrivileges = async () => {
  const getToken = async () => {
    try {
      const apiResponse = await axios.post(
        "https://marineeyeapi.online/auth/v1/token",
        {
          username: process.env.REACT_APP_API_KEY,
          password: process.env.REACT_APP_SECRET,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!apiResponse.data) {
        alert("Wrong username or password");
      } else {
        const tokens = apiResponse.data; // Assuming the API response contains the tokens
        return tokens; // Return the tokens
      }
    } catch (e) {
      return null; // Return null in case of an error
    }
  };
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(
    localStorage.getItem("userProfile"),
    SECRET_KEY
  );

  let userProfile = bytes.toString(CryptoJS.enc.Utf8);
  let lsObject = JSON.parse(userProfile);
  //console.log("****************");
  //console.log(lsObject.id);
  if (lsObject[0]) {
    lsObject = lsObject[0];
  }
  const apiUrl =
    "https://marineeyeapi.online/api/auth/v1/users/id/" + lsObject.id;

  // console.log("****************");
  // console.log(lsObject);

  try {
    const token = await getToken();
    if (token) {
      const apiResponse = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const privileges = JSON.parse(apiResponse.data[0].privileges);

      // Get the value of `blue_traker_vessels`
      const blueTrakerVessels = privileges.blue_traker_vessels;
      const spireVessels = privileges.spire_vessels;
      const bothVessels = privileges.both_vessels;

      console.log("blueTrakerVessels" + " " + blueTrakerVessels);
      console.log("spireVessels" + " " + spireVessels);

      const element = document.getElementById("type");

      if (blueTrakerVessels && !spireVessels) {
        Cookies.set("DeviceType", "B");

        if (element) {
          element.style.display = "none";
        }
      } else if (!blueTrakerVessels && spireVessels) {
        Cookies.set("DeviceType", "S");

        if (element) {
          element.style.display = "none";
        }
      } else if (blueTrakerVessels && spireVessels) {
        //Cookies.set('DeviceType', "B");

        if (element) {
          element.style.display = "inline";
        }
      } else {
        if (element) {
          element.style.display = "inline";
        }
      }

      const encryptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(apiResponse.data),
        SECRET_KEY
      ).toString();
      localStorage.setItem("userProfile", encryptedValue);
    } else {
      console.error("Failed to obtain token");
    }
  } catch (error) {
    console.error("Error:", error);
  }

  getToken().then((token) => {
    axios
      .get(`https://marineeyeapi.online/trip/v1/alerts/*`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res);
        const data1 = res.data;
        const countPending = data1.filter(
          (item) => item.status === null || item.status === "Pending"
        ).length;

        Cookies.set("PendingAlerts", countPending);

        ///console.log("Count of Pending alerts:", countPending);
      });

    //const total = res.headers.get("x-total-count");
  });
};

export default userPrivileges;
