import axios from "axios";
import CryptoJS from "crypto-js";

const getIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching the IP address:", error);
    return null;
  }
};

const getToken = async () => {
  try {
    const apiResponse = await axios.post(
      "https://marineeyeapi.online/auth/v1/token",
      {
        username: process.env.REACT_APP_API_KEY,
        password: process.env.REACT_APP_SECRET,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!apiResponse.data) {
      alert("Wrong username or password");
    } else {
      return apiResponse.data; // Return the tokens
    }
  } catch (e) {
    console.error("Error obtaining token:", e);
    return null; // Return null in case of an error
  }
};

const logUserAction = async (action, description, profile = "yes") => {
  const apiUrl = "https://marineeyeapi.online/logger/v1/add";
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

  try {
    let payload;
    if (profile == "yes") {
      const bytes = CryptoJS.AES.decrypt(
        localStorage.getItem("userProfile"),
        SECRET_KEY
      );
      const userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      const ipAddress = await getIpAddress();

      payload = {
        username: userProfile[0]?.username || userProfile.username,
        usertype: userProfile[0]?.roles[0]?.name || userProfile.roles[0],
        action: action,
        datetime: new Date(),
        description: description,
        ip: ipAddress || "IP Not Found", // Include IP address in payload
      };
    } else {
      const ipAddress = await getIpAddress();

      payload = {
        username: description,
        usertype: "unknown",
        action: action,
        datetime: new Date(),
        description: description,
        ip: ipAddress || "IP Not Found", // Include IP address in payload
      };
    }

    const token = await getToken();
    if (token) {
      const apiResponse = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Log saved:", apiResponse.data);
    } else {
      console.error("Failed to obtain token");
    }
  } catch (error) {
    console.error("Error logging user action:", error);
  }
};

export default logUserAction;
